// const testRoutes = [
//   { path: "" },
//   { path: "my-profile" },
//   { path: "leafletmap" },
//   { path: "allFormComponent" },
//   { path: "InputField" },
//   { path: "editor" },
//   { path: "stepperForms" },
//   { path: "FormsWithValidation" },
//   { path: "progress" },
//   { path: "button" },
//   { path: "tab" },
//   { path: "autocomplete" },
//   { path: "checkbox" },
//   { path: "radiobox" },
//   { path: "selectbox" },
//   { path: "transfer" },
//   { path: "alert" },
//   { path: "modal" },
//   { path: "message" },
//   { path: "breadcrumb" },
//   { path: "dropdown" },
//   { path: "op_tag" },
//   { path: "op_timeline" },
//   { path: "uppy" },
//   { path: "dropzone" },
// ];

const options = [
  {
    key: "cms",
    label: "sidebar.cms",
    leftIcon: "ion-ios-cog",
    children: [
      {
        key: "authors",
        label: "sidebar.authors",
      },
      {
        key: "menu",
        label: "sidebar.menu",
      },
      {
        key: "notifications",
        label: "sidebar.notifications",
      },
      {
        key: "pages",
        label: "sidebar.pages",
      },
      {
        key: "settings",
        label: "sidebar.settings",
      },
      {
        key: "users",
        label: "sidebar.users",
      },
      {
        key: "landings",
        label: "sidebar.landings",
      },
    ],
  },
  {
    key: "articles-list",
    label: "sidebar.articles",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "categories",
        label: "sidebar.categories",
      },
      {
        key: "articles",
        label: "sidebar.articles",
      },
      {
        key: "articles-orders",
        label: "sidebar.articlesTransactions",
      },
    ],
  },
  {
    key: "subscriptions",
    label: "sidebar.subscriptions",
    leftIcon: "ion-card",
    children: [
      {
        key: "subscriptions",
        label: "sidebar.subscriptions",
      },
      {
        key: "question-subscription",
        label: "sidebar.questionSubscription",
      },
      {
        key: "orders",
        label: "sidebar.orders",
      },
      {
        key: "transactions",
        label: "sidebar.transactions",
      },
    ],
  },
  {
    key: "questions",
    label: "sidebar.questions",
    eventKey: "sidebar.questions",
    leftIcon: "ion-ios-help",
  },
  {
    key: "trainings",
    label: "sidebar.trainings",
    eventKey: "sidebar.trainings",
    leftIcon: "ion-android-list",
  },

  {
    key: "banners",
    label: "sidebar.banners",
    eventKey: "sidebar.banners",
    leftIcon: "ion-ios-list",
  },
  {
    key: "tags",
    label: "sidebar.tags",
    eventKey: "sidebar.tags",
    leftIcon: "ion-ios-pricetag",
  },

  {
    key: "newsletters",
    label: "sidebar.newsletters",
    eventKey: "sidebar.newsletters",
    leftIcon: "ion-ios-email",
  },
  {
    key: "comments",
    label: "sidebar.comments",
    eventKey: "sidebar.comments",
    leftIcon: "ion-ios-chatboxes",
  },
  {
    key: "contacts",
    label: "sidebar.contacts",
    eventKey: "sidebar.contacts",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "discounts",
    label: "sidebar.discounts",
    eventKey: "sidebar.discounts",
    leftIcon: "ion-cash",
  },
  {
    key: "checklist-notes",
    label: "sidebar.checklist-notes",
    eventKey: "sidebar.checklist-notes",
    leftIcon: "ion-android-checkmark-circle",
  },

  // {
  //   key: "test",
  //   label: "sidebar.test",
  //   leftIcon: "ion-bag",
  //   children: testRoutes.map((q) => ({
  //     key: q.path,
  //     label: "sidebar." + q.path,
  //   })),
  // },
];
export default options;
